import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Article from '../components/article'
import Section from '../components/section'
import Accordion from '../components/accordion'
import FormContact from '../components/formContact'

function ContactPage() {
  const { t } = useTranslation()

  const handleSubmit = async (values, setSuccess) => {
    const res = await addToMailchimp(
      values.email,
      {
        NAME: values.name,
        MESSAGE: values.message,
      },
      'https://anayjose.us19.list-manage.com/subscribe/post?u=913dbc71926ff10619b39e45b&amp;id=41fb80b34e'
    )

    if (res.result === 'success') setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title={t('contact.title')}>
      <SEO title={t('contact.title')} />
      <Article
        display="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        maxWidth="1760px"
        marginX="auto"
      >
        <Section title={t('contact.form.title')} subtitle={t('contact.form.subtitle')}>
          <FormContact
            hideOnSuccess
            nameLabel={t('contact.form.name.label')}
            namePlaceholder={t('contact.form.name.placeholder')}
            emailLabel={t('contact.form.email.label')}
            emailPlaceholder={t('commons.emailPlaceholder')}
            emailRequiredText={t('contact.form.email.required')}
            emailInvalidText={t('contact.form.email.invalid')}
            messageLabel={t('contact.form.message.label')}
            messageRequiredText={t('contact.form.message.required')}
            buttonText={t('contact.form.button')}
            successMessage={t('contact.form.successMessage')}
            onSubmit={handleSubmit}
          />
        </Section>

        <Section title={t('contact.faqs.title')}>
          <Accordion faqs={t('contact.faqs.questions', { returnObjects: true })} />
        </Section>
      </Article>
    </Layout>
  )
}

export default ContactPage
