import React from 'react'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'

import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/core'

function Accordion({ faqs }) {
  return (
    <ChakraAccordion>
      {faqs.length !== 0 &&
        faqs.map((faq) => (
          <AccordionItem key={v4()}>
            <AccordionHeader>
              <Box flex="1" textAlign="left">
                {faq.question}
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <Text>{faq.answer}</Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </ChakraAccordion>
  )
}

Accordion.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
}

Accordion.defaultProps = {
  faqs: [],
}

export default Accordion
